import styled, { css } from 'styled-components';
import breakpointsMedia from 'theme/utils/breakpointsMedia';
import { get, isEmpty } from 'theme/utils/functions';
import propToStyle from 'theme/utils/propToStyle';

const arrow =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACuSURBVHgB7dfRDYIwFIXhU+MAjuAIbiAjOELdgA1kA91AncgRHAE3uB4TIljLG7QNOX/SNISSfkl5KaCUimdmW44zh0cJEfK0vityR0Rrv+VFEVDbf9lRjVBCCVU6ah35+MSpwvx57gXn3HF0BRccLH3V0LAKTC+kbzN8cOFbihtOe0zfLtyc3XlkHqn7/JeRo7ohR8III8wiMR2oLQbTgR7FYIDvRfHCUUMp1fcGIxxOJCX6LXkAAAAASUVORK5CYII=';

const buttonModifiers = {
  medium: () => css`
    width: 32rem;
  `,
  full: () => css`
    width: 100%;
  `,
  center: () => css`
    margin-left: auto;
    margin-right: auto;
  `,
  secondaryTheme: theme => css`
    border: 0;
    justify-content: center;
    background: ${get(theme, 'colors.base.00')};

    span {
      background: linear-gradient(
        106deg,
        #8f6ead 1.4%,
        #8f6ead 24.33%,
        #8f6ead 26.33%,
        #8f6ead 32.31%,
        #8f6ead 35.3%,
        #8f6ead 36.3%,
        #8e6fae 37.29%,
        #8d71b0 38.29%,
        #8b74b2 39.29%,
        #8b78b4 40.29%,
        #897bb7 41.28%,
        #877fba 42.28%,
        #8781bc 43.28%,
        #8786c0 44.27%,
        #838ac4 45.27%,
        #8091c9 46.27%,
        #7996cd 47.26%,
        #769bd1 48.26%,
        #6ca0d6 49.26%,
        #66a4d8 50.26%,
        #5eaad5 51.25%,
        #59add0 52.25%,
        #51b2ce 53.25%,
        #4bb6cb 54.24%,
        #44bac6 55.24%,
        #46bac0 56.24%,
        #49babb 57.24%,
        #4fbab5 58.23%,
        #50b9b1 59.23%,
        #51b9ad 60.23%,
        #56b9a8 61.22%,
        #5abaa6 62.22%,
        #5eba9f 63.22%,
        #61ba9d 64.21%,
        #61ba9a 65.21%,
        #62ba97 66.21%,
        #62ba97 67.21%,
        #62ba97 70.2%,
        #62ba97 76.18%,
        #62ba97 78.17%,
        #62ba97 101.11%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &::after {
      display: none;
    }

    &::before {
      content: '';
      z-index: -1;
      top: -0.2rem;
      left: -0.2rem;
      right: -0.2rem;
      bottom: -0.2rem;
      position: absolute;
      border-radius: 0.6rem;
      width: calc(100% + 0.4rem);
      height: calc(100% + 0.4rem);
      background: linear-gradient(
        105.59deg,
        #8f6ead 1.4%,
        #8f6ead 24.33%,
        #8f6ead 26.33%,
        #8f6ead 32.31%,
        #8f6ead 35.3%,
        #8f6ead 36.3%,
        #8e6fae 37.29%,
        #8d71b0 38.29%,
        #8b74b2 39.29%,
        #8b78b4 40.29%,
        #897bb7 41.28%,
        #877fba 42.28%,
        #8781bc 43.28%,
        #8786c0 44.27%,
        #838ac4 45.27%,
        #8091c9 46.27%,
        #7996cd 47.26%,
        #769bd1 48.26%,
        #6ca0d6 49.26%,
        #66a4d8 50.26%,
        #5eaad5 51.25%,
        #59add0 52.25%,
        #51b2ce 53.25%,
        #4bb6cb 54.24%,
        #44bac6 55.24%,
        #46bac0 56.24%,
        #49babb 57.24%,
        #4fbab5 58.23%,
        #50b9b1 59.23%,
        #51b9ad 60.23%,
        #56b9a8 61.22%,
        #5abaa6 62.22%,
        #5eba9f 63.22%,
        #61ba9d 64.21%,
        #61ba9a 65.21%,
        #62ba97 66.21%,
        #62ba97 67.21%,
        #62ba97 70.2%,
        #62ba97 76.18%,
        #62ba97 78.17%,
        #62ba97 101.11%
      );
    }
  `,
  secondaryRevertTheme: theme => css`
    border: 0;
    justify-content: center;
    background: ${get(theme, 'colors.base.00')};

    span {
      background: linear-gradient(
        106deg,
        #62ba97 1.4%,
        #62ba97 24.33%,
        #62ba97 26.33%,
        #62ba97 32.31%,
        #62ba97 35.3%,
        #62ba97 36.3%,
        #61ba9a 37.29%,
        #61ba9d 38.29%,
        #5eba9f 39.29%,
        #5abaa6 40.29%,
        #56b9a8 41.28%,
        #51b9ad 42.28%,
        #50b9b1 43.28%,
        #4fbab5 44.27%,
        #49babb 45.27%,
        #46bac0 46.27%,
        #44bac6 47.26%,
        #4bb6cb 48.26%,
        #51b2ce 49.26%,
        #59add0 50.26%,
        #5eaad5 51.25%,
        #66a4d8 52.25%,
        #6ca0d6 53.25%,
        #769bd1 54.24%,
        #7996cd 55.24%,
        #8091c9 56.24%,
        #838ac4 57.24%,
        #8786c0 58.23%,
        #8781bc 59.23%,
        #877fba 60.23%,
        #897bb7 61.22%,
        #8b78b4 62.22%,
        #8b74b2 63.22%,
        #8d71b0 64.21%,
        #8e6fae 65.21%,
        #8f6ead 66.21%,
        #8f6ead 67.21%,
        #8f6ead 70.2%,
        #8f6ead 76.18%,
        #8f6ead 78.17%,
        #8f6ead 101.11%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &::after {
      display: none;
    }

    &::before {
      content: '';
      z-index: -1;
      top: -0.2rem;
      left: -0.2rem;
      right: -0.2rem;
      bottom: -0.2rem;
      position: absolute;
      border-radius: 0.6rem;
      width: calc(100% + 0.4rem);
      height: calc(100% + 0.4rem);
      background: linear-gradient(
        105.59deg,
        #62ba97 1.4%,
        #62ba97 24.33%,
        #62ba97 26.33%,
        #62ba97 32.31%,
        #62ba97 35.3%,
        #62ba97 36.3%,
        #61ba9a 37.29%,
        #61ba9d 38.29%,
        #5eba9f 39.29%,
        #5abaa6 40.29%,
        #56b9a8 41.28%,
        #51b9ad 42.28%,
        #50b9b1 43.28%,
        #4fbab5 44.27%,
        #49babb 45.27%,
        #46bac0 46.27%,
        #44bac6 47.26%,
        #4bb6cb 48.26%,
        #51b2ce 49.26%,
        #59add0 50.26%,
        #5eaad5 51.25%,
        #66a4d8 52.25%,
        #6ca0d6 53.25%,
        #769bd1 54.24%,
        #7996cd 55.24%,
        #8091c9 56.24%,
        #838ac4 57.24%,
        #8786c0 58.23%,
        #8781bc 59.23%,
        #877fba 60.23%,
        #897bb7 61.22%,
        #8b78b4 62.22%,
        #8b74b2 63.22%,
        #8d71b0 64.21%,
        #8e6fae 65.21%,
        #8f6ead 66.21%,
        #8f6ead 67.21%,
        #8f6ead 70.2%,
        #8f6ead 76.18%,
        #8f6ead 78.17%,
        #8f6ead 101.11%
      );
    }
  `,
  gradientTheme: theme => css`
    border: 0;
    gap: ${get(theme, 'spacings.xxxl')};
    justify-content: space-between;
    background: linear-gradient(
      89deg,
      #8f6ead 0%,
      #4bb6cb 66.88%,
      #62ba97 100%
    );

    span {
      color: ${get(theme, 'colors.base.00')};
    }
  `,
  cleanTheme: theme => css`
    padding: 0;
    border: 0;
    background-color: transparent;
    text-transform: inherit;

    span {
      color: ${get(theme, 'colors.base.500')};
    }
  `,
};

const ButtonWrapper = styled.button`
  ${({ theme, $size, center, disabled, $themeColor, $ghost }) => css`
    border: 0;
    width: 100%;
    display: flex;
    cursor: pointer;
    text-align: left;
    position: relative;
    align-items: center;
    border-radius: 0.4rem;
    text-transform: uppercase;
    justify-content: space-between;
    padding: ${get(theme, 'spacings.smd')};

    ${isEmpty($ghost)
      ? css`
          background: linear-gradient(
            89deg,
            #8f6ead 0%,
            #4bb6cb 66.88%,
            #62ba97 100%
          );
        `
      : ''}

    ${breakpointsMedia({
      sm: css`
        ${buttonModifiers?.[$size]?.() || buttonModifiers.full()};
      `,
    })}

    &::after {
      content: '';
      width: 2.4rem;
      height: 2.4rem;
      display: inline-flex;
      background-size: contain;
      background-image: url(${arrow});
    }

    ${!!center && buttonModifiers.center()}
    ${buttonModifiers?.[$themeColor ?? 'secondaryTheme']?.(theme)}

    ${disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
  `}

  ${propToStyle({ prop: '$cssPadding', css: 'padding' })}
  ${propToStyle({ prop: '$cssWidth', css: 'width' })}
  ${propToStyle({ prop: '$cssFontWeight', css: 'fontWeight' })}
`;

export default ButtonWrapper;
